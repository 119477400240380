import React, { useState } from "react";
import { styled, keyframes } from "@stitches/react";
import { violet, blackA, mauve } from "@radix-ui/colors";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import cn from "classnames";
import Button from "../ui/Button";
import { Link } from "react-router-dom";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: "fixed",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",
  padding: 25,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  "&:focus": { outline: "none" },
});

function Content({ children, ...props }) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </DialogPrimitive.Portal>
  );
}

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  fontWeight: 500,
  color: mauve.mauve12,
  fontSize: 17,
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: "10px 0 20px",
  color: mauve.mauve11,
  fontSize: 15,
  lineHeight: 1.5,
});

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  position: "absolute",
  top: 10,
  right: 10,

  "&:hover": { backgroundColor: violet.violet4 },
  "&:focus": { boxShadow: `0 0 0 2px ${violet.violet7}` },
});

const ExteriorDecor = (
  <div className="color-modal__series color-modal__series--exterior hidden">
    <p>
      <b>Zewnętrzne / Dekoratorskie </b>
    </p>
    <p>Seria paneli do zastosowań wewnętrznych i zewnętrznych.</p>
    <p>
      Kolor paneli na stronie może różnić się od rzeczywistego koloru, w
      zależności od ustawień Twojego monitora
    </p>
  </div>
);

const Decor = (
  <div className="color-modal__series color-modal__series--decor">
    <p>
      <b>Dekoratorskie</b>
    </p>
    <p>Seria paneli do zastosowań wewnętrznych.</p>
    <p>
      Kolor paneli na stronie może różnić się od rzeczywistego koloru, w
      zależności od ustawień Twojego monitora
    </p>
  </div>
);

const SequinDialog = ({ data, onOpenChange }) => {
  const [videoUrl, setVideoUrl] = useState(data.video);
  const [shape, setShape] = useState("rectangle");

  console.log(data);

  const handleShapeChange = (newShape) => {
    if (newShape === "circle") {
      setVideoUrl(data.videoCircle);
    } else if (newShape === "poly") {
      setVideoUrl(data.videoPoly);
    } else if (newShape === "rectangle") {
      setVideoUrl(data.video);
    }

    setShape(newShape);
  };

  return (
    <Dialog modal={false} defaultOpen onOpenChange={onOpenChange}>
      <DialogContent>
        <div className="modal-body">
          <div
            className="color-modal__video"
            style={{
              maxWidth: "900px",
              minHeight: "505px",
            }}
          >
            <video
              className="nocontrols"
              preload="auto"
              loop={true}
              muted={true}
              autoPlay={true}
              playsInline={true}
              width="900"
              height="505"
              src={videoUrl}
              type="video/mp4"
              style={{
                display: "block",
                maxWidth: "900px",
                objectFit: "cover",
              }}
            ></video>
          </div>
          <div
            className="color-modal__content"
            style={{ overflow: "scroll", height: "505px" }}
          >
            <div className="color-modal__content-left">
              <div className="color-modal__title">{data.title}</div>
              <hr style={{ opacity: 0.4 }} />
              Wybierz kształt cekina:
              <div className="color-modal__shapes" style={{ marginTop: 10 }}>
                <button
                  className={cn("color-modal__shape1", {
                    active: shape === "circle",
                  })}
                  onClick={() => handleShapeChange("circle")}
                ></button>
                <button
                  className={cn("color-modal__shape2", {
                    active: shape === "rectangle",
                  })}
                  onClick={() => handleShapeChange("rectangle")}
                ></button>
                <button
                  className={cn("color-modal__shape3", {
                    active: shape === "poly",
                  })}
                  onClick={() => handleShapeChange("poly")}
                ></button>
              </div>
              Rozmiar cekina <span className="color-modal__size">30mm;</span>{" "}
              <span className="color-modal__size">20mm</span>
              <hr style={{ opacity: 0.4 }} />
              Użytkowanie:
              {data.series === "Exterior" ? ExteriorDecor : Decor}
            </div>
            <div className="color-modal__content-right">
              <Link to="/#kontakt">
                <Button
                  className="color-modal__order"
                  data-toggle="modal"
                  data-target="#contact-modal"
                  text="Napisz do nas"
                />
              </Link>
            </div>
          </div>
        </div>

        <DialogClose asChild>
          <IconButton aria-label="Close">
            <Cross2Icon />
          </IconButton>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default SequinDialog;
