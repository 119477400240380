import React from "react";
import ReactDOM from "react-dom";

import "./assets/fonts/Freight.woff";
import "./assets/fonts/MOD20.TTF";

import "./index.css";
import "./index_mobile.css";

// import balony from './assets/img/balony_rozowe.jpg';

import App from "./App";
import Header from "./Header";
import Footer from "./Footer";

import Home from "./Home";

import facebookIcon from "./assets/img/icons/facebook.webp";
import instagramIcon from "./assets/img/icons/instagram.webp";

import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router, Route } from "react-router-dom";

import Collection from "./lib/util";
import Kolory from "./pages/kolory";
import Gallery from "../src/Gallery";

function replaceProps(obj) {
  let recursive = function recursive(o) {
    for (let prop in o) {
      if (typeof o[prop] === "object") {
        recursive(o[prop]);
      }
      if (o[prop + "_en"]) {
        o[prop] = o[prop + "_en"];
        delete obj[prop + "_en"];
      }
    }
  };
  recursive(obj);
  return obj;
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.db = new Collection(process.env.REACT_APP_TOKEN);

    this.data_PL = {
      deviceType: "desktop",
      language: "pl",
      menus: [],
      medias: [
        {
          title: "facebook",
          url: "https://www.facebook.com/SolaAir-Poland-Cekinowe-%C5%9Acianki-Cekinowy-Blask-102318201305842",
          image: facebookIcon,
        },
        {
          title: "instagram",
          url: "https://www.instagram.com/solaairpoland/",
          image: instagramIcon,
        },
      ],
      home: {
        textWithHeader: { header: "", body: "", buttonText: "" },
        form: {
          header: "",
          description: "",
          fields: [],
          privacypolicy: "",
          buttonText: "",
        },
      },
    };
    this.data_EN = {};

    this.state = {
      ...this.data_PL,
    };

    this.resolveDevice = this.resolveDevice.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  async componentDidMount() {
    // const pass = window.prompt("Podaj hasło");
    // if (pass !== process.env.REACT_APP_TESTPASS) {
    // window.location.replace("about:blank");
    // }

    let home = {};
    // home.topHero = await this.db.get('heroImage', 'id', 'home-main');
    // home.bottomHero = await this.db.get('heroImage', 'id', 'home-bottom');
    home.textWithHeader = await this.db.get("texts", "name", "about-us");
    home.form = await this.db.get("forms", "name", "contactForm");

    let menus = await this.db.getAll("menus");
    let galleries = await this.db.getAll("galleries");

    this.data_PL = {
      ...this.data_PL,
      home,
      menus,
      galleries,
    };

    this.setState({
      ...this.data_PL,
    });

    this.data_EN = replaceProps(JSON.parse(JSON.stringify(this.data_PL)));

    window.addEventListener("resize", () => {
      this.resolveDevice();
    });

    this.resolveDevice();
  }

  resolveDevice() {
    let deviceType;
    if (window.outerWidth >= 640) {
      deviceType = "desktop";
    } else {
      deviceType = "mobile";
    }

    if (deviceType !== this.state.deviceType) {
      this.setState({
        ...this.state,
        deviceType,
      });
    }
  }

  handleLanguageChange(language) {
    if (language === "en") {
      this.setState({
        ...this.data_PL,
        deviceType: this.state.deviceType,
        language: "pl",
      });
    } else if (language === "" || language === "pl") {
      this.setState({
        ...this.data_EN,
        deviceType: this.state.deviceType,
        language: "en",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Router>
            <App>
              <Header
                language={this.state.language}
                deviceType={this.state.deviceType}
                medias={this.state.medias}
                menus={this.state.menus}
                onLanguageChange={this.handleLanguageChange}
              />
              <Route exact path="/kolory">
                <Kolory />
              </Route>
              <Route exact path="/galeria">
                <Gallery
                  header="Galeria"
                  buttonText="Skontaktuj się z nami"
                  galleryName="main-gallery"
                />
              </Route>
              <Route exact path="/">
                <Home medias={this.state.medias} data={this.state.home} />
              </Route>
              <Footer />
            </App>
          </Router>
        </div>
      </React.Fragment>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
