import React from "react";

import Collection from "../lib/util";

const URL = `${process.env.REACT_APP_HOST}/`;

class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      image: null,
    };
  }

  componentDidMount() {
    this.db = new Collection(process.env.REACT_APP_TOKEN);
    this.db.get("heroImage", "id", this.props.id).then((result) => {
      if (result) {
        this.setState({
          title: result.title,
          image: URL + result.photo.path,
        });
      }
    });
  }

  render() {
    return (
      <div
        id={`hero-${this.props.id}`}
        className="b-hero after-header flex parallax"
        style={{
          backgroundImage: `url(${this.state.image})`,
        }}
      >
        <div className="b-hero--text">
          <h1>{this.state.title}</h1>
        </div>
      </div>
    );
  }
}

export default Hero;
