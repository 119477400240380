import React from "react";
import { withRouter } from "react-router-dom";

import HashButton from "./blocks/ui/HashButton";
import Spacer from "./blocks/Spacer";
import Collection from "./lib/util";
import ThreeImages from "./blocks/ThreeImages";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.db = new Collection(process.env.REACT_APP_TOKEN);

    this.state = {
      threeImages: [],
    };
  }

  async componentDidMount() {
    const all = await this.db.getAll("ThreeImages");
    const allSorted = all.sort((a, b) => {
      return (
        parseInt(a.id.match(/(\d+)[^-]*$/)[0]) -
        parseInt(b.id.match(/(\d+)[^-]*$/)[0])
      );
    });

    this.setState({ threeImages: allSorted });
  }

  render() {
    return (
      <div className="b-galleryPage">
        <div className="b-galleryPage--top">
          <h1 className="b-galleryPage--header">{this.props.header}</h1>
          <p className="b-galleryPage--description">{this.props.description}</p>
          <HashButton
            className="b-galleryPage--contactButton"
            text={this.props.buttonText}
            to="/#kontakt"
          />
        </div>
        <Spacer />
        <div className="b-galleryPage--content">
          {this.state.threeImages.map((item, index) => (
            <>
              <ThreeImages
                {...item}
                key={item.id}
                variant={index % 2 === 0 ? "left" : "right"}
              />
              <Spacer />
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(Gallery);
