import "whatwg-fetch";
import { site_url } from "../config";

class Collection {
  constructor(token) {
    this.token = token;
  }

  get(collection, key, value, language = "") {
    return new Promise((resolve) => {
      fetch(
        `${site_url}/cockpit/api/collections/get/${collection}?token=${this.token}`
      )
        .then((res) => res.json())
        .then((res) => {
          let result = null;
          res.entries.forEach(function (entry) {
            if (entry[key] === value) {
              result = entry;
              return;
            }
          });
          resolve(result);
        });
    });
  }

  getAll(collection) {
    return new Promise((resolve) => {
      fetch(
        `${site_url}/cockpit/api/collections/get/${collection}?token=${this.token}`
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.entries);
        });
    });
  }
}

export default Collection;
