import React from "react";

import Spacer from "./Spacer";
import Collection from "../lib/util";

class ThreeImages extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      first: null,
      second: null,
      third: null,
    };
  }

  componentDidMount() {
    this.db = new Collection(process.env.REACT_APP_TOKEN);
    this.db.get("ThreeImages", "id", this.props.id).then((result) => {
      if (result) {
        this.setState({
          first: process.env.REACT_APP_HOST + result.first.path,
          second: process.env.REACT_APP_HOST + result.second.path,
          third: process.env.REACT_APP_HOST + result.third.path,
          variant: this.props.variant,
        });
      }
    });
  }

  handleClick() {}

  render() {
    let spaceAfter = this.props.spaceAfter === true ? <Spacer /> : "";
    let variant = this.state.variant || "left";
    let html;

    let bigOne = (
      <div
        className="b-threeImages--image b-threeImages--bigOne"
        style={{ backgroundImage: `url(${this.state.first}` }}
      ></div>
    );
    let smallerOnes = (
      <div className={"b-threeImages--smallerOnes " + variant}>
        <div
          className="b-threeImages--image b-threeImages--smallImage up"
          style={{ backgroundImage: `url(${this.state.second}` }}
        ></div>
        <div
          className="b-threeImages--image b-threeImages--smallImage down"
          style={{ backgroundImage: `url(${this.state.third}` }}
        ></div>
      </div>
    );

    if (variant === "left") {
      html = (
        <React.Fragment>
          {bigOne}
          {smallerOnes}
        </React.Fragment>
      );
    } else {
      html = (
        <React.Fragment>
          {smallerOnes}
          {bigOne}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="b-threeImages flex">{html}</div>
        {spaceAfter}
      </React.Fragment>
    );
  }
}

export default ThreeImages;
