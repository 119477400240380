import React from "react";

function Footer() {
  return (
    <div className="b-footer">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "space-between",
          // maxWidth: "768px",
          margin: "auto",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <p id="adres-korespondencji">Adres do korespondencji:</p>
          <p>SolaAir Poland</p>
          <p>
            ul. Indiry Gandhi 17B <br />
            02-776 Warszawa
          </p>
          <p>
            +48 604 562 748 <br />
            +48 501 302 632
            <br />
          </p>
        </div>
        <div style={{ flexGrow: 1 }}>
          <p id="adres-biura">Adres biura:</p>
          <p>SolaAir Poland </p>
          <p>
            Hala Koszyki - Biura MINDSPACE
            <br />
            ul. Koszykowa 61
            <br />
            00-667 Warszawa
            <br />
          </p>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        © {new Date().getFullYear()} by SolaAirPoland. Warszawa.
      </div>
    </div>
  );
}

export default Footer;
