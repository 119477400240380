import React, { useState, useMemo } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import SequinDialog from "../../blocks/ui/SequinDialog";

export const LazyButton = (props) => {
  return (
    <button
      className={props.className}
      style={{ backgroundImage: `url(${props["data-src"]})` }}
    >
      {props.children}
    </button>
  );
};

export default function Kolory() {
  const PaletteTrigger = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);

    const dialogData = useMemo(
      () => ({
        title: `${children[1].props.children} ${children[2].props.children}`,
        video: props["data-video"],
        videoCircle: props["data-video-circle"],
        videoPoly: props["data-video-poly"],
        series: props["data-series"],
      }),
      [children, props]
    );

    return (
      <div {...props} onClick={() => setOpen(true)}>
        {open && (
          <SequinDialog
            data={{ ...dialogData }}
            onOpenChange={(val) => setOpen(val)}
          />
        )}
        {children}
      </div>
    );
  };

  return (
    <>
      <div className="colors-container">
        <div className="col-md-12" style={{ marginTop: "3rem" }}>
          <div id="comp_4234e7e45f3bf4ea9858a78eff926623">
            <div className="panel-group" id="colors-palette">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse34"
                      className=""
                    >
                      Błyszczące z efektem lustrzanym<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse34" className="panel-collapse collapse in">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15229"
                      data-video="https://solaair.com/upload/iblock/f9d/f9ded48a4d6174dcf3da97a658363ead.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/260/260b479a8571905be0bf9eb30eb9a03a.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/a9a/a9adfffda95278d70689e75f6a7c32ac.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FF0000" }}
                      ></button>
                      <div className="palette-item__number">#02</div>
                      <div className="palette-item__name">red</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15235"
                      data-video="https://solaair.com/upload/iblock/e20/e20e2f6cd4ddbaefe069dec48f31cee2.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/bd6/bd6e8df3be0e1bbeba5a76c11f11f106.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/da8/da8c1efd1012e85727b7ec13922a3d20.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#9B002F" }}
                      ></button>
                      <div className="palette-item__number">#03</div>
                      <div className="palette-item__name">bordeaux</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15236"
                      data-video="https://solaair.com/upload/iblock/59d/59d7681efd742869076986d4b48c376b.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/fb1/fb1d79ca96d9562ff5d0b2b212ab3a99.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/c47/c471db643a905fc9ab07c7212b310a23.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#DC0042" }}
                      ></button>
                      <div className="palette-item__number">#04</div>
                      <div className="palette-item__name">ruby red</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15237"
                      data-video="https://solaair.com/upload/iblock/44a/44a884e2905ed627548a16b26c2a8295.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/61a/61a1d639ffaf66656170a2a9fbffcd14.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/ad2/ad2d502752ceaa4f16c0073cfdfed079.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#6200DE" }}
                      ></button>
                      <div className="palette-item__number">#06</div>
                      <div className="palette-item__name">purple</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15238"
                      data-video="https://solaair.com/upload/iblock/5ca/5ca42aa3e68ae7efbfa747071448387c.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/b9d/b9da7108d7a1aad9c709a65a02927fae.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/749/74906514de0818e389566f0d426a9c96.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "A60095" }}
                      ></button>
                      <div className="palette-item__number">#09</div>
                      <div className="palette-item__name">violet</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15239"
                      data-video="https://solaair.com/upload/iblock/2a4/2a44589e6087d59c766330a4fadc1a02.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/f82/f82b5b245e86217938265a942fedd7dc.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/588/5882d7ce6f5f8928d66d6680a75ab21c.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#EF00AC" }}
                      ></button>
                      <div className="palette-item__number">#12</div>
                      <div className="palette-item__name">magenta</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15240"
                      data-video="https://solaair.com/upload/iblock/477/477685f22c978c7c9b4289456cb5a483.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/af9/af91f820255d9b249be5d1b48608a6d6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/6bc/6bc5fb1b6d1a78c077750c1ffc261f8d.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#DE4300" }}
                      ></button>
                      <div className="palette-item__number">#51</div>
                      <div className="palette-item__name">paprika</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15241"
                      data-video="https://solaair.com/upload/iblock/f3f/f3f3a95a0894513d122f0f53af21ade6.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/ed7/ed774172bb4649c35e9284e00307ee8a.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/066/066f3db523b0c3725986640d28e5dca4.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#E16C00" }}
                      ></button>
                      <div className="palette-item__number">#49</div>
                      <div className="palette-item__name">copper</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15242"
                      data-video="https://solaair.com/upload/iblock/298/2983542fafe2d2aba23203a83d295fbc.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/10d/10dec3d9d263a55fb58e77cde7183293.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/443/443f6a5414e389d7203a8d8465e7b86a.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FF8970" }}
                      ></button>
                      <div className="palette-item__number">#42</div>
                      <div className="palette-item__name">pink gold</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15243"
                      data-video="https://solaair.com/upload/iblock/2dc/2dce1b961a4aacee26a8d5144d687ed0.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/d33/d33bc080c153b141e033cc5bc884047b.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/ead/eadad95ab8471196d74841e8e5a0157b.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#E5A500" }}
                      ></button>
                      <div className="palette-item__number">#47</div>
                      <div className="palette-item__name">gold</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_16568"
                      data-video="https://solaair.com/upload/iblock/1ed/1ed31a348712c29bf72c0bded131968a.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/efb/efb1510eac4b2f7de07f50dcb954a0a6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/74e/74ec7266b83e8c7c8effffb2607ccd50.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#EED683" }}
                      ></button>
                      <div className="palette-item__number">#44</div>
                      <div className="palette-item__name">Light gold</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15245"
                      data-video="https://solaair.com/upload/iblock/846/8460ec8691b3e5615f0d2162b4ca830b.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/7e2/7e2adf17b4ea42984388551e21429ed5.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/aac/aac5721f78011b2476d3fbe1294b61c2.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#008D28" }}
                      ></button>
                      <div className="palette-item__number">#20</div>
                      <div className="palette-item__name">green mirror</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15246"
                      data-video="https://solaair.com/upload/iblock/b1c/b1ce032a016bd0a3bb318756e2e6b0af.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/68d/68d24795da9665f129792b5385472f4f.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/397/3971c20699687da625c175462eaf303f.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#00BFD2" }}
                      ></button>
                      <div className="palette-item__number">#27</div>
                      <div className="palette-item__name">turquoise</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15247"
                      data-video="https://solaair.com/upload/iblock/2bc/2bcbcb58d13fec96164a8debd4daa9d2.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/3f9/3f95af58543668016890a8093a62c7e4.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/7e1/7e1be14263dd3506ae0216bbc74cddc2.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#0090D1" }}
                      ></button>
                      <div className="palette-item__number">#29</div>
                      <div className="palette-item__name">light blue</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15248"
                      data-video="https://solaair.com/upload/iblock/4da/4da2763a3a6f991b0359bce0e497f7b0.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/64b/64ba7a8c008ce9823652d0914b1a24eb.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/4dc/4dc1ce400754c5b0907061b4dddfec46.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#283EFF" }}
                      ></button>
                      <div className="palette-item__number">#33</div>
                      <div className="palette-item__name">blue</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15228"
                      data-video="https://solaair.com/upload/iblock/0e3/0e3c61f357d5116e4c12c167e06cc7d7.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/99c/99cf80f14895e31c6103c2dfaa3dbfb7.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/f5d/f5d352cf95fe85ccf04b77dc98919671.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#411700" }}
                      ></button>
                      <div className="palette-item__number">#39</div>
                      <div className="palette-item__name">brown</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15249"
                      data-video="https://solaair.com/upload/iblock/351/351452590fc5f422304f6567209c931b.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/5ca/5caae39c9d08f6db596ac00ff4464672.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/b17/b1728317e0d2b4d44d4736bc9ee38ff3.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#C9C9C9" }}
                      ></button>
                      <div className="palette-item__number">#99</div>
                      <div className="palette-item__name">mirror silver</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse35"
                      className="collapsed"
                    >
                      Błyszczące z efektem satynowym<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse35" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15230"
                      data-video="https://solaair.com/upload/iblock/afe/afe06cf21771e7ac1ed292dd0a9b70a6.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/956/9560ac9394e75a492bef96836fbaa5d0.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/17a/17a2e206c7238018f5127268c621dab7.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#A8D6F8" }}
                      ></button>
                      <div className="palette-item__number">#30</div>
                      <div className="palette-item__name">sky blue</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15250"
                      data-video="https://solaair.com/upload/iblock/f62/f624a0aec9bb3c8cad7a82452a98bbff.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/90e/90e301baa7df0d61e5e26307986fdbfa.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/1d9/1d9e03635dd0525a5d02f4a646b050e4.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#94FFEC" }}
                      ></button>
                      <div className="palette-item__number">#25</div>
                      <div className="palette-item__name">mint</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15251"
                      data-video="https://solaair.com/upload/iblock/4a6/4a69a296fef5e202e1ef64f7579f10d3.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/437/437260895a414ec3b2d0e3c6b0242995.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/1a8/1a82a0474f273c4448fabb4069afa241.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#D4F2AE" }}
                      ></button>
                      <div className="palette-item__number">#24</div>
                      <div className="palette-item__name">pistachio</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15252"
                      data-video="https://solaair.com/upload/iblock/e1d/e1d729af3abd26a9e580aa3f774e51c0.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/c98/c980d726bd0279f11544ee114953c3c6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/e47/e472bb2c704edb2b6817a063626e2d63.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FDFFB1" }}
                      ></button>
                      <div className="palette-item__number">#16</div>
                      <div className="palette-item__name">citric</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15253"
                      data-video="https://solaair.com/upload/iblock/4a0/4a0eefa4e38dffbb7748e190c370d46a.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/1e3/1e3e7fef56b6830b69f854dd0ef3c1fd.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/504/504c9b23b72c547aa809a5aff014b03d.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFECC7" }}
                      ></button>
                      <div className="palette-item__number">#45</div>
                      <div className="palette-item__name">vanilla</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15254"
                      data-video="https://solaair.com/upload/iblock/f77/f77be9d6122ddef3c1ed8969157ff12d.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/3ce/3ce2591819c9915048808b8c2ab2cfba.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/83e/83e27e14599f6d4ab10e0ab79db1c403.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFD6B8" }}
                      ></button>
                      <div className="palette-item__number">#46</div>
                      <div className="palette-item__name">champagne</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15255"
                      data-video="https://solaair.com/upload/iblock/b5b/b5b749c8fac5bb484ebb6eecc2311051.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/9a9/9a9d0b7e0dca2501945eb308b0a99f07.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/457/457509d04dfaeac1a80132b384e0a4e6.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFD0D0" }}
                      ></button>
                      <div className="palette-item__number">#40</div>
                      <div className="palette-item__name">powder</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15256"
                      data-video="https://solaair.com/upload/iblock/b6e/b6e07244b0e5eb9494fcd8e03675738d.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/b4a/b4a3a0b41214c834c16db1a866d8605b.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/f67/f6744c39aadbaaa2972da27fb52cba86.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFC3F9" }}
                      ></button>
                      <div className="palette-item__number">#41</div>
                      <div className="palette-item__name">lilac</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15257"
                      data-video="https://solaair.com/upload/iblock/682/682d8f2217bb09c0aafdb44315e4c7c0.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/f46/f4620a711accd5cb05e6d3a54d15aab5.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/773/773671803b480e6d57aad46d7fe51933.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#D8B1FF" }}
                      ></button>
                      <div className="palette-item__number">#07</div>
                      <div className="palette-item__name">lavender</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15258"
                      data-video="https://solaair.com/upload/iblock/612/612b7c0ef2178f64042a4a57fed9a59b.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/c30/c3038821dea9383415029927dedaa62b.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/98d/98d60953641a5cc7810abf1420e0615a.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#DFDFDF" }}
                      ></button>
                      <div className="palette-item__number">#96</div>
                      <div className="palette-item__name">white chrome</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15259"
                      data-video="https://solaair.com/upload/iblock/4e1/4e14c542fa05868d608261cb5eaf67fa.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/378/37843e5566e537032cb7b0fc02f92253.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/f16/f160528889c278212ee6d5c01d3d8319.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FF0000" }}
                      ></button>
                      <div className="palette-item__number">#902</div>
                      <div className="palette-item__name">scarlet</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15260"
                      data-video="https://solaair.com/upload/iblock/be1/be1e0e1e620a2e13d1cb0f66649294e3.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/d66/d6649e5f87bdeb58f41f76e2e78bc3b4.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/5a7/5a747b97a49ddd33685083d31cdd1c2e.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#9B002F" }}
                      ></button>
                      <div className="palette-item__number">#903</div>
                      <div className="palette-item__name">cherry</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15261"
                      data-video="https://solaair.com/upload/iblock/837/8377ce0287d01d1bcedb7830a1601b3f.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/209/20988cd3e233283f11dbd079869056a9.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/f8f/f8f05d2c7f26363998ccc10bf9ccbc31.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#DC0042" }}
                      ></button>
                      <div className="palette-item__number">#904</div>
                      <div className="palette-item__name">pomegranate</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15262"
                      data-video="https://solaair.com/upload/iblock/5a6/5a66c27a34d585374ea7ed5ef4ae1d83.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/721/721ff2663943422815d04de9967993ea.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/cb1/cb1f309f1945b19ad554cad1276d92a9.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#EF00AC" }}
                      ></button>
                      <div className="palette-item__number">#912</div>
                      <div className="palette-item__name">fuchsia</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15263"
                      data-video="https://solaair.com/upload/iblock/d62/d6235d65795ecaebf159b1c96627131a.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/97f/97f436aaef47dde6019e50ed5e09eb2e.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/8f0/8f0ef0dc87cfb2b4b4e427105afe17c2.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFE600" }}
                      ></button>
                      <div className="palette-item__number">#918</div>
                      <div className="palette-item__name">yellow</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15264"
                      data-video="https://solaair.com/upload/iblock/1eb/1eb6bb93d4ef4bed5317c1e205b86e70.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/a80/a80befb617b12f27da247fe0a3641b65.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/f9d/f9d715d9c8b9c0f2c76df616fb9c0d92.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#008D28" }}
                      ></button>
                      <div className="palette-item__number">#920</div>
                      <div className="palette-item__name">green</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15265"
                      data-video="https://solaair.com/upload/iblock/8c2/8c2d97bdeac98c1680eefd6021465897.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/eff/eff6a4fae746ef7001a195973402c165.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/b1f/b1fd2b8e0c0d92fbe590313d3dfcd14a.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#00BFD2" }}
                      ></button>
                      <div className="palette-item__number">#927</div>
                      <div className="palette-item__name">aquamarine</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15266"
                      data-video="https://solaair.com/upload/iblock/171/17114f42ff86783ee4b981732fba7392.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/ef2/ef2b7272e075d8ba45af61f7bc91fef3.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/7a5/7a53914638e25d074e57802f3e35fcce.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#0090D1" }}
                      ></button>
                      <div className="palette-item__number">#929</div>
                      <div className="palette-item__name">topaz</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15267"
                      data-video="https://solaair.com/upload/iblock/117/117751df1090173d9c52029cba3e161a.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/70e/70e910e7ca1339f04e99f5754766fd05.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/0d9/0d9e45f10101d111f3736427e1186bcc.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#283EFF" }}
                      ></button>
                      <div className="palette-item__number">#933</div>
                      <div className="palette-item__name">ultramarine</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15268"
                      data-video="https://solaair.com/upload/iblock/1c9/1c9fcc46c2b369e8bd9eb0a249e5f511.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/4d8/4d858a6afa956fd32b64183935a8c2ed.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/8b8/8b83b6ca6f1e90d4da80809f04e182e3.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#411700" }}
                      ></button>
                      <div className="palette-item__number">#939</div>
                      <div className="palette-item__name">chestnut</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15269"
                      data-video="https://solaair.com/upload/iblock/28c/28cd1127842264103678bb5af9455481.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/322/3225d536b8988b0ac4c57bd00e185c15.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/285/28524997c690597b82a9973e0750de6c.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FF8970" }}
                      ></button>
                      <div className="palette-item__number">#942</div>
                      <div className="palette-item__name">coral</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15270"
                      data-video="https://solaair.com/upload/iblock/608/608afe7ead1991840ce721d30061e227.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/7da/7daa5346d16eacff95b86d669f8d76f6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/77e/77e16b9311ce8bbfa187740435e8ad15.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#EED683" }}
                      ></button>
                      <div className="palette-item__number">#944</div>
                      <div className="palette-item__name">light amber</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15271"
                      data-video="https://solaair.com/upload/iblock/28a/28a271dc254112a258efd81bf380302a.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/53e/53e970b220e1a92bdd2460119dde9a2a.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/3fe/3fec38b200c5c8ffaf44d5b83da3d68b.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#E5A500" }}
                      ></button>
                      <div className="palette-item__number">#947</div>
                      <div className="palette-item__name">amber</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse37"
                      className="collapsed"
                    >
                      Błyszczące<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse37" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15272"
                      data-video="https://solaair.com/upload/iblock/935/935c0399abe0b2d83cd263751f244f87.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/755/75561c0e04f1f19d1025c05f602f0f80.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/3b4/3b421376d844dc8ccbb09cb8dcd20f3a.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FF7A00" }}
                      ></button>
                      <div className="palette-item__number">#14</div>
                      <div className="palette-item__name">orange</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15273"
                      data-video="https://solaair.com/upload/iblock/d95/d9549a053961ca23760d5536fbe4cbf5.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/a20/a20332c27a5ce0d54e28c685fbf86b91.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/211/21134093eeca10aa6f16df2465e4be3f.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#FFE600" }}
                      ></button>
                      <div className="palette-item__number">#18</div>
                      <div className="palette-item__name">yellow</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15274"
                      data-video="https://solaair.com/upload/iblock/529/5293adc656558aefd4244d3ee6e34695.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/d30/d30089b0f2a1832cc819f0f2e71ff807.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/50c/50c6a9b68929d9ccdde7e1bd47727d5f.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#00B45D" }}
                      ></button>
                      <div className="palette-item__number">#21</div>
                      <div className="palette-item__name">green</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15275"
                      data-video="https://solaair.com/upload/iblock/693/693741562de8089caa16aa9a3b23bf0e.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/fd4/fd438a6599d8b65cbc575cab23d95746.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/01e/01ed85ef5471244596d5b094ddf9632c.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#0A0A0A" }}
                      ></button>
                      <div className="palette-item__number">#37</div>
                      <div className="palette-item__name">gloss black</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15276"
                      data-video="https://solaair.com/upload/iblock/6cb/6cbeeb8b60c1470c6c6cc2025114fcce.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/d0c/d0c8e57213f2cd127f0b748575094bf6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/773/77340cf5d338c4c629c7cfc2d723b435.mp4"
                      data-series="Exterior"
                    >
                      <button
                        className="palette-item__image"
                        style={{ background: "#F6F6F6" }}
                      ></button>
                      <div className="palette-item__number">#90</div>
                      <div className="palette-item__name">gloss white</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse36"
                      className="collapsed"
                    >
                      Kameleon<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse36" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15277"
                      data-video="https://solaair.com/upload/iblock/b84/b848ad9dd23bab13d65ac636069f87f1.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/d78/d78e67f387dc1dc52904bbcfc265ba83.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/579/579f6508128aafec8cd8f71e5320bda4.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#B4DAFF 0%,#F19DFF 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#011</div>
                      <div className="palette-item__name">blue / pink</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15232"
                      data-video="https://solaair.com/upload/iblock/a85/a85e51a2b2f776e99485c4f921e8cdef.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/e17/e17c3fe045956d956b951a3c86a888f9.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/96e/96e3a60c6de9823e1768531b18a08667.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#FEFFB8 0%,#FFC2E1 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#020</div>
                      <div className="palette-item__name">pink / yellow</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15233"
                      data-video="https://solaair.com/upload/iblock/503/50390368ae5675b2aa8c0d8bb8dfdc46.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/de4/de448e7e7d05f6bf8a3f19eab7766a76.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/78f/78fe149be5ef63f1f9dfad0f6c686423.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#FFF3AE 0%,#ED7DFF 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#030</div>
                      <div className="palette-item__name">lilac / yellow</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15279"
                      data-video="https://solaair.com/upload/iblock/c36/c3689ca56df6ff45abcb35611703d109.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/0ea/0ea578a607b9764dccec6b2b6e467b6f.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/7f8/7f835eddf6df3aef94f77ac0133da83b.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#FFF3AD 0%,#A2B0FA 50%,#ED7DFF 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#031</div>
                      <div className="palette-item__name">
                        blue / purple / yellow
                      </div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15365"
                      data-video="https://solaair.com/upload/iblock/8b4/8b4285b6968240cb09b23cd5b2b372a2.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/2c5/2c5cc18333920ecace9fcfb0db94bc6b.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/aff/aff1ddeaa13780d2d69702f6060c3a68.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#ff8c00 0%,#ff7f50 50%,#ff69b4 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#035</div>
                      <div className="palette-item__name">orange / crimson</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15278"
                      data-video="https://solaair.com/upload/iblock/7b9/7b91db0a56ed677e359d064966aa9068.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/1ea/1eae08aa7c8b31aae152230049538475.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/5bb/5bb98624c3765df92162bbdc2c427243.mp4"
                      data-series="Decor"
                    >
                      <button
                        className="palette-item__image"
                        style={{
                          backgroundImage:
                            "linear-gradient(25deg,#00A8FB 0%,#30CB5F 100%)",
                        }}
                      ></button>
                      <div className="palette-item__number">#040</div>
                      <div className="palette-item__name">green / blue</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse38"
                      className="collapsed"
                    >
                      Holograficzne z tęczowym efektem<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse38" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15280"
                      data-video="https://solaair.com/upload/iblock/e81/e81f1c41af26f4ae9bd89885f0e40c3a.mp4"
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/22b/22b8e262c4f5037383dc2e0ecd845489.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#100</div>
                      <div className="palette-item__name">rainbow</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15281"
                      data-video=""
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/661/661f8a5a7bbaa22c812ffa7216e4591d.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#101</div>
                      <div className="palette-item__name">rainbow lines</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15282"
                      data-video="https://solaair.com/upload/iblock/723/723dfe6b4d456e81f52ceb7671ecdf88.mp4"
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/93e/93e72122cb5615b57cfdce8ffdc07102.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#200</div>
                      <div className="palette-item__name">rainbow crystals</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15283"
                      data-video="https://solaair.com/upload/iblock/a42/a42462f250e68960cb41ea7d814fc170.mp4"
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/ebd/ebde92df5dbc312532be602e7bdf9522.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#300</div>
                      <div className="palette-item__name">rainbow dots</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15284"
                      data-video="https://solaair.com/upload/iblock/7ea/7ea74578bad6d484f6bc4ab7c45faf9f.mp4"
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/641/6410abe9439de3542b2d9ccb1ad29ced.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#400</div>
                      <div className="palette-item__name">rainbow sand</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse39"
                      className="collapsed"
                    >
                      Matowe z brokatowym efektem tekstury<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse39" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15285"
                      data-video="https://solaair.com/upload/iblock/b36/b367b6e1a1dfef84aa3bf875c6334665.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/0cb/0cbd511206c4a6c872a9ea6d45c645c5.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/a9d/a9d315168729212e60dbf1f57b79f055.mp4"
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/e2f/e2ff48f17132aeec6fc483f3508004e6.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#540</div>
                      <div className="palette-item__name">sparkling powder</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15286"
                      data-video="https://solaair.com/upload/iblock/b21/b2149c27eb862b0f62e8e353664fdfd5.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/7d4/7d44b5cca438b18d0c52a4c18b09072e.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/009/009b3919469ba345882869a5ad3187b3.mp4"
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/fc3/fc3deb6109b2c03cc3b3574e585396d2.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#544</div>
                      <div className="palette-item__name">sparkling gold</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15287"
                      data-video="https://solaair.com/upload/iblock/bfc/bfc427788eb928609e5e5ca63c4effc1.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/fcb/fcb1df728f6068fde1c9969e97d7b47f.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/977/977b5b975c1f5b57a3fa46dc681bc269.mp4"
                      data-series="Decor"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/cfc/cfcf1b27265758d09f58e1b8c71e7bfb.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#599</div>
                      <div className="palette-item__name">sparkling silver</div>
                      <div className="palette-item__series">Decor</div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse40"
                      className="collapsed"
                    >
                      Panele z efektem plusku<span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse40" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15289"
                      data-video="https://solaair.com/upload/iblock/06d/06d0c98dbced985b68e2d650334e50e6.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/289/28940386541b936aeabc774fdaa24cec.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/8a2/8a23e0cfd21dcb86a2cfbc7941026feb.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/2d9/2d995f79a44917dd7719b01ef56a98d5.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#37/47</div>
                      <div className="palette-item__name">black / gold</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15290"
                      data-video="https://solaair.com/upload/iblock/036/036961644dedf906021e2f488c933c76.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/007/007bc94d6155ed59dd86eeb654791984.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/533/533d6cc38444be3acfd472568da74f65.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/d37/d37f1f054d7a9d0d865deb53dd8c592b.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#37/99</div>
                      <div className="palette-item__name">black / silver</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15291"
                      data-video="https://solaair.com/upload/iblock/547/547c5d987d3fb07652e177306c163b27.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/e32/e32c6a6b1fed14124fd3064de90bc434.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/ac6/ac6c716370bc55e52a7f66e2c7cb7df3.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/17b/17b4b08ef689250eccf88154ed75cb92.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#37/100</div>
                      <div className="palette-item__name">black / rainbow</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15292"
                      data-video="https://solaair.com/upload/iblock/ae7/ae72d06082ee7d5b5f12c31d660b829c.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/25a/25ae5abb6c0e195fd7166f6f302718cd.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/a2e/a2eaee2edf2e7900fb38b61207c18eeb.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/21d/21d8c167f1f12135e7a7dc319fe74d3a.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#90/99</div>
                      <div className="palette-item__name">white / silver</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15293"
                      data-video="https://solaair.com/upload/iblock/709/70993217880545e61bd76d845cb35a68.mp4"
                      data-video-circle=""
                      data-video-poly=""
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/fab/fab089065541d7189833f78d8a75eb63.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#90/100</div>
                      <div className="palette-item__name">white / rainbow</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="panel-title">
                    <button
                      data-toggle="collapse"
                      href="#collapse41"
                      className="collapsed"
                    >
                      SolaAir z dowolnym nadrukiem produkowanym pod klienta
                      <span></span>
                    </button>
                  </div>
                </div>
                <div id="collapse41" className="panel-collapse collapse ">
                  <div className="panel-body">
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15294"
                      data-video="https://solaair.com/upload/iblock/452/452545df92defde940ad000c60ddd3ec.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/381/3818704540023a4cfcc8058d8e31a2d6.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/8e1/8e11243c63f23dcec3b9102ae94ee432.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/351/351fcaf44da681d9b72c6c78db719ea6.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#print #1</div>
                      <div className="palette-item__name">mirror base</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15295"
                      data-video="https://solaair.com/upload/iblock/362/3620764e9d8a0f669ba987ff0e0fd287.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/36b/36b37e7816ab904bfd588f361701867e.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/3ce/3cee9b2474dd14c8bbd68e762ceacbd1.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/7ba/7bacac03ac7b5afee91bfdfb64c76092.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#print #2</div>
                      <div className="palette-item__name">satin base</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                    <PaletteTrigger
                      className="palette-item"
                      id="bx_3218110189_15296"
                      data-video="https://solaair.com/upload/iblock/d5f/d5fafd7fe18ddd604985218a27f204c3.mp4"
                      data-video-circle="https://solaair.com/upload/iblock/f7c/f7caeac160fc5d09879cdcc8c0ba536e.mp4"
                      data-video-poly="https://solaair.com/upload/iblock/e39/e3956e6e628503c534e79c028d2c34d0.mp4"
                      data-series="Exterior"
                    >
                      <LazyButton
                        className="lazy palette-item__image"
                        data-src="https://solaair.com/upload/iblock/bdc/bdcc5ca5e9537d241d1d2e7065f35ad7.jpg"
                      ></LazyButton>
                      <div className="palette-item__number">#print #3</div>
                      <div className="palette-item__name">white base</div>
                      <div className="palette-item__series">
                        Exterior / Decor
                      </div>
                    </PaletteTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <article>
            <p>
              <strong>Panele SolaAir są prezentowane w różnych seriach:</strong>
            </p>
            <p>
              <b>SolaAir Exterior</b> - seria paneli do zastosowań zewnętrznych.
              Wszystkie kolory tej serii posiadają wysoki stopień ochrony przed
              promieniowaniem ultrafioletowym (99,9%) oraz odporność na warunki
              atmosferyczne.
            </p>
            <p>
              <b>SolaAir Decor</b> - jasne i bogate kolory oraz efekty
              specjalne, z których wiele nie ma odpowiedników i są tworzone
              przez nas specjalnie dla dekoratorów.
            </p>
            <p>
              <b>SolaAir Print</b> - opatentowana technologia nakładania
              dowolnego wielokolorowego obrazu lub ekskluzywnego koloru, zgodnie
              z Twoimi wymaganiami.
            </p>
            <p>
              Polimerowa baza paneli z serii Exterior, Decor i Print nie różni
              się niczym i ma maksymalną wydajność (odporność na promieniowanie
              ultrafioletowe, odporność na warunki atmosferyczne, wytrzymałość
              na rozciąganie, elastyczność i przezroczystość).
            </p>
          </article>
        </div>
      </div>
    </>
  );
}
