import React from "react";

import Spacer from "../blocks/Spacer";

import Input from "../blocks/ui/Input";
import CalendarInput from "../blocks/ui/CalendarInput";
import Textarea from "../blocks/ui/Textarea";
import Button from "../blocks/ui/Button";
import SocialMedia from "../blocks/SocialMedia";

import "whatwg-fetch";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.fields = {};

    this.state.sent = false;
    this.state.valid = false;

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    let fields = this.state.fields;

    const json = JSON.stringify({ form: { ...fields } });

    for (const field of [
      "name",
      "email",
      "phone",
      "wymiar_scianki",
      "kolor_ksztalt",
      "message",
    ]) {
      if (!fields[field]?.trim()) return;
    }

    fetch(
      `https://solaairpoland.com/cockpit/api/forms/submit/contact?token=${process.env.REACT_APP_TOKEN}`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: json,
      }
    )
      .then((entry) => entry.json())
      .then((entry) => {
        this.setState({ sent: true, fields: {} });
      });

    await fetch(`${process.env.REACT_APP_HOST}/sendform.php`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: json,
    });

    event.preventDefault();
    return false;
  }

  handleChange(event, field) {
    let fields = this.state.fields;
    this.setState({
      fields: {
        ...fields,
        [field]: event.target.value,
      },
    });
  }

  render() {
    let fields = this.props.fields.fields;
    let fieldsElements = [];
    if (fields) {
      fieldsElements = fields.map((field, index) => {
        let name = Object.keys(field)[0];
        let data = field[name];

        if (data.type === "textarea") {
          return (
            <Textarea
              disabled={this.state.sent}
              placeholder={data.placeholder}
              key={name}
              label={data.label}
              required={data.required}
              rows="10"
              resizable={false}
              onChange={(e) => this.handleChange(e, name)}
            />
          );
        } else if (data.type === "date") {
          return (
            <CalendarInput
              disabled={this.state.sent}
              label={data.label}
              key={name}
              required={data.required}
              onChange={(e) => this.handleChange(e, name)}
            />
          );
        } else {
          return (
            <Input
              disabled={this.state.sent}
              label={data.label}
              key={name}
              required={data.required}
              onChange={(e) => this.handleChange(e, name)}
            />
          );
        }
      });
    }

    return (
      <div className="b-contactForm" id={this.props.id}>
        <div className="b-contactForm--textContainer">
          <div className="b-contactForm--header text-center">
            {this.props.header}
          </div>
          <div
            className="b-contactForm--text text-center"
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          ></div>
        </div>

        <Spacer margin="2" />
        <SocialMedia className="text-center" medias={this.props.medias} />
        <Spacer margin="1" />

        <form
          className="b-contactForm--form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          {fieldsElements}
          <span className="b-contactForm--privacypolicy">
            {this.props.privacypolicy}
          </span>
          <Spacer margin="2.5" />

          {this.state.sent === false ? (
            <Button
              text={this.props.buttonText}
              align="center"
              onClick={this.handleSubmit}
            />
          ) : (
            <p className="b-contactForm--thankyou">Dziękujemy za wiadomość.</p>
          )}
        </form>
      </div>
    );
  }
}

export default ContactForm;
