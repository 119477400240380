import React from "react";

import Collection from "../lib/util";
import HashButton from "../blocks/ui/HashButton";

class TextWithHeader extends React.Component {
  constructor(props) {
    super(props);

    this.collection = new Collection(process.env.REACT_APP_TOKEN);
    this.state = {
      data: {
        header: null,
        body: null,
      },
    };
  }

  render() {
    return (
      <div
        className="b-textWithHeader flex"
        id={this.props.id || this.props.name}
      >
        <div className="b-textWithHeader--header text-center">
          {this.props.header}
        </div>
        <div
          className="b-textWithHeader--text text-center"
          style={{ textAlign: "justify" }}
        >
          <div dangerouslySetInnerHTML={{ __html: this.props.body }}></div>
          <HashButton
            className="b-galleryPage--contactButton"
            text={this.props.buttonText}
            to="/#kontakt"
          />
        </div>
      </div>
    );
  }
}

export default TextWithHeader;
